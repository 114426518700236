import React, { useRef, useEffect } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { WebLinksAddon } from "xterm-addon-web-links";
import useResize from "./useResize";
import "xterm/css/xterm.css";
import "./App.css";
import "./AsciiArt.css";

const AsciiArt = () => {
  const size = useResize();

  const asciiLrg = `
  ░█████╗░███╗░░██╗░█████╗░██╗░░██╗░█████╗░██╗███╗░░██╗
  ██╔══██╗████╗░██║██╔══██╗██║░░██║██╔══██╗██║████╗░██║
  ██║░░██║██╔██╗██║██║░░╚═╝███████║███████║██║██╔██╗██║
  ██║░░██║██║╚████║██║░░██╗██╔══██║██╔══██║██║██║╚████║
  ╚█████╔╝██║░╚███║╚█████╔╝██║░░██║██║░░██║██║██║░╚███║
  ░╚════╝░╚═╝░░╚══╝░╚════╝░╚═╝░░╚═╝╚═╝░░╚═╝╚═╝╚═╝░░╚══╝
  
  ░█████╗░███╗░░██╗░██████╗░███████╗██╗░░░░░░██████╗
  ██╔══██╗████╗░██║██╔════╝░██╔════╝██║░░░░░██╔════╝
  ███████║██╔██╗██║██║░░██╗░█████╗░░██║░░░░░╚█████╗░
  ██╔══██║██║╚████║██║░░╚██╗██╔══╝░░██║░░░░░░╚═══██╗
  ██║░░██║██║░╚███║╚██████╔╝███████╗███████╗██████╔╝
  ╚═╝░░╚═╝╚═╝░░╚══╝░╚═════╝░╚══════╝╚══════╝╚═════╝░
  
  `;
  const asciiSml = `

  ░█████╗░░█████╗░░█████╗░
  ██╔══██╗██╔══██╗██╔══██╗
  ██║░░██║██║░░╚═╝███████║
  ██║░░██║██║░░██╗██╔══██║
  ╚█████╔╝╚█████╔╝██║░░██║
  ░╚════╝░░╚════╝░╚═╝░░╚═╝

  `;

  return (
    <pre className="ascii-art">{size.width < 768 ? asciiSml : asciiLrg}</pre>
  );
};

const TerminalComponent = () => {
  const containerRef = useRef(null);
  let terminal;
  let fitAddon;
  let inputBuffer = "";
  const sampleMembers = [
    {
      name: "lewi",
      role: "builder, degen, & potato",
      link: "https://twitter.com/lewifree",
    },
    {
      name: "the architect",
      role: "The only true wisdom is in knowing you know nothing",
      link: "https://twitter.com/ar31t3ct",
    },
    {
      name: "Gareth Fenney",
      role: "VC cum full time degen",
    },
    {
      name: "fabian0x",
      role: "Degen, DAOist, Coin Connoisseur",
      link: "https://twitter.com/fabian_0x",
    },
    {
      name: "adamthewhale",
      role: "DegenScore co-founder. Spent most of my sheckles on poorly placed Ethereum TXs.",
      link: "https://twitter.com/adamthesalmon",
    },
    {
      name: "0xflipstate",
      role: "Degen who survived the formation of the Interoperable Alliance",
      link: "https://twitter.com/0xflipstate",
    },
    {
      name: "Benj_pirate",
      role: "Ex tag poker player, now DEGENing around while trying to build cool things in Crypto ",
    },
    {
      name: "sergey69420",
      role: "Ultra Sound Money Maximalist since 2017",
      link: "https://twitter.com/sergey69420",
    },
    {
      name: "vfat",
      role: "DeFi builder and degen, proprietor of vfat.tools and vfat.io",
      link: "twitter.com/vfat0",
    },
    {
      name: "gus",
      role: "DeFi curious with an ode to NFTs",
    },
    {
      name: "dragos",
      role: "DeFi degen, Music NFT Collector & Content Creator",
      link: "https://twitter.com/DrgStefanescu",
    },
    {
      name: "cavkie",
      role: "In a search of Onchain game that doesn't suck.",
      link: "https://twitter.com/DrgStefanescu",
    },
    {
      name: "cavkie",
      role: "Internet monies zealot since 2017",
      link: "https://twitter.com/0xd063",
    },
    {
      name: "nickbtts",
      role: "co-founder Velodrome, investoooor with Spaceship",
      link: "twitter.com/nickbtts",
    },
    {
      name: "blurr",
      role: "[could not be reached for comment]",
    },
  ].sort(() => Math.random() - 0.5);

  const samplePortfolio = [
    {
      name: "Ethena Finance",
      description: "Synthetic Dollar and Internet Native Yield",
      link: "https://www.ethena.fi/",
    },
    {
      name: "Mode Network",
      description:
        "An Ethereum l2 that rewards you for growing the network via new economic mechanisms",
      link: "https://www.mode.network/",
    },
    {
      name: "Pixelmon",
      description: "Building the first fractionalised gaming IP",
      link: "https://hub.pixelmon.ai/",
    },
    {
      name: "Arcadia Finance",
      description:
        "AI powered yield optimizations & single click transactions.",
      link: "https://www.arcadia.finance/",
    },
    {
      name: "vfat",
      description: "The og yield farming aggregator, and portfolio viewer.",
      link: "https://vfat.io/",
    },
    {
      name: "Mithraeum",
      description: "A fully on-chain grand strategy game.",
      link: "https://mithraeum.io/",
    },
    {
      name: "QuickSnap Finance",
      description: "Incentivized Voting, Decentralized.",
      link: "hhttps://quicksnap.finance/",
    },
  ];

  const displayMembers = () => {
    terminal.writeln("\r\nMembers:");
    sampleMembers.forEach((member) => {
      member.link
        ? terminal.writeln(
            `\x1b]8;;${member.link}\x07${member.name}\x1b]8;;\x07 - ${member.role}`
          )
        : terminal.writeln(`${member.name} - ${member.role}`);
    });
  };

  const displayPortfolio = () => {
    terminal.writeln("\r\nPortfolio:");
    samplePortfolio.forEach((project) => {
      project.link
        ? terminal.writeln(
            `\x1b]8;;${project.link}\x07${project.name}\x1b]8;;\x07 - ${project.description}`
          )
        : terminal.writeln(`${project.name} - ${project.description}`);
    });
  };

  const displayAbout = () => {
    terminal.writeln("");
  };

  const displayHelp = () => {
    terminal.writeln("\r\nAvailable commands:");
    // terminal.writeln("/about - Information about Onchain Angels");
    terminal.writeln("/members - List of members");
    terminal.writeln("/portfolio - List of investments");
    terminal.writeln("/help - Show available commands");
  };

  useEffect(() => {
    terminal = new Terminal({
      cursorBlink: true,
      fontFamily: "Roboto Mono, monospace",
    });
    fitAddon = new FitAddon();
    terminal.loadAddon(fitAddon);

    terminal.loadAddon(new WebLinksAddon());

    terminal.open(containerRef.current);
    fitAddon.fit();
    terminal.writeln("Onchain Angels:");
    terminal.writeln(
      "A syndicate comprised of some of the top on-chain actors in the space."
    );
    terminal.writeln("");
    terminal.writeln(
      "Founders, developers, traders, farmers & investors alike, the group has supported/tested/broken hundreds of projects across every chain imaginable."
    );
    terminal.writeln("");

    terminal.write("Use ");
    terminal.write("\x1b[34m/help\x1b[0m"); // Blue color for "help" text
    terminal.writeln(" for available commands.");
    terminal.onKey(({ key }) => {
      switch (key) {
        case "\r":
          terminal.write("\r\n");
          switch (inputBuffer) {
            case "/members":
              displayMembers();
              break;
            case "/portfolio":
              displayPortfolio();
              break;
            case "/about":
              displayAbout();
              break;
            case "/help":
              displayHelp();
              break;
            default:
              terminal.writeln("Unknown command");
          }
          inputBuffer = "";
          break;
        case "\x7F":
          terminal.write("\b \b");
          inputBuffer = inputBuffer.slice(0, -1);
          break;
        default:
          terminal.write(key);
          inputBuffer += key;
      }
    });

    return () => {
      terminal.dispose();
    };
  }, []);

  return <div ref={containerRef} className="terminal"></div>;
};

function App() {
  return (
    <div className="App">
      <AsciiArt />
      <TerminalComponent />
    </div>
  );
}

export default App;
